import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MapsService } from '../services/maps.service';
import { InternalInterceptor } from './interceptors/token.interceptor';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';

const initialize = (
  storage: StorageService,
  auth: AuthService,
  maps: MapsService
) => async () => {
  await storage.init();
  await auth.init();
  await maps.init();

  // Remove #initial-loader element from DOM
  document.getElementById('initial-loader')?.remove();
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternalInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [
        StorageService,
        AuthService,
        MapsService
      ],
      multi: true
    }
  ]
})
export class GlobalModule { }
