import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Coordinates } from '../global/models/coordinates.models';
import { Browser } from '@capacitor/browser';

const api = environment.googleMapsApiKey;

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  readonly realTimeLocation$ = new BehaviorSubject<Coordinates | null>(null);

  constructor() { }

  init() {
    this._manageRealTimeLocation();
  }

  get apiKey(): string {
    return api;
  }

  get userLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        err => reject(err));
    });
  }

  openGoogleMaps(coordinates: Coordinates) {
    const url = `https://www.google.com/maps/search/?api=1&query=${coordinates?.lat},${coordinates?.lng}`;
    Browser.open({ url });
  }

  private _manageRealTimeLocation() {
    navigator.geolocation.watchPosition(position => {
      this.realTimeLocation$.next({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });
  }

}
