import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class InternalInterceptor implements HttpInterceptor {

  constructor(
    private readonly auth: AuthService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const request = this.addHeaders(req);

    return next.handle(request).pipe(
      catchError((err: any) => {
        // 401 status code
        if (err.status === 401 && err.error?.message === 'Unauthorized') {
          return this.manageUnauthorizedError(next, request, err);
        }

        return throwError(() => err);
      })
    );
  }


  private addHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const token = this.auth.token;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return request;
  }

  private manageUnauthorizedError(
    next: HttpHandler,
    request: HttpRequest<unknown>,
    err: any
  ): Observable<HttpEvent<unknown>> {

    return this.auth.renewAccessToken().pipe(
      switchMap(() => {
        request = this.addHeaders(request);
        return next.handle(request);
        
      }),
      catchError(() => {
        this.auth.logout();
        return throwError(() => err);
      })
    );

  }
}
