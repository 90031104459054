import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage?: Storage;

  constructor(private readonly storage: Storage) {

  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async get<T>(key: string): Promise<T | null> {
    let data;

    try {
      data = await this._storage?.get(key);
    } catch (error) {
      data = null;   
    }

    return data;
  }

  remove(key: string) {
    return this._storage?.remove(key);
  }

  set(key: string, value: any) {
    return this._storage?.set(key, value);
  }
}
