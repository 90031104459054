<ion-app>

  <div class="environment" *ngIf="!production">
    {{ environment }}
  </div>
  <ion-menu side="start" menuId="first" contentId="main">
    <ion-header>
      <ion-toolbar>
        <ion-title color="primary">RodaBernat</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
          
      <ion-list>

        <ion-menu-toggle [autoHide]="false">
          <ion-item>
            {{ auth.whoAmI?.name || 'Rodabernapp' }}
          </ion-item>
          <ion-item>
            {{ auth.whoAmI?.email }}
          </ion-item>
        </ion-menu-toggle>
      
      </ion-list>

      <div class="logout">
        <ion-item class="ion-activatable" lines="none" (click)="logout()">
          <ion-ripple-effect></ion-ripple-effect>
          <ion-icon color="primary" slot="start" name="log-out-outline"></ion-icon>
          Cerrar sesión
        </ion-item>
      </div>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="main"></ion-router-outlet>

</ion-app>
