import { environment } from 'src/environments/environment';
import { Coordinates } from './coordinates.models';

export interface PostRetirada {
  asfaltos?: boolean;

  sacas?: boolean;
  pv?: boolean;

  vallas?: boolean;
  pp?: boolean;

  message?: string;
}

export enum WorkType {
  ORDERS = 'ORDERS',
  ASPHALTS = 'ASPHALTS',
  FENCES = 'FENCES',
  SACKS = 'SACKS',
  MORTAR_MIX = 'MORTAR_MIXES'
}

export interface Nota {
  admin: boolean;
  timestamp: string;
  data: string;
  autor: string;
}

export interface IParte {
  id: number;
  fecha: string;
  codigo: string;
  urgente?: boolean;
  direccion: string;
  poblacion: string;
  cliente: string;
  oficial: string;
  completado: boolean;
  data: string[];
  extra: string[];
  observaciones: string;
  type: WorkType;
  files: string[];
  coordinates?: Coordinates;
  notas: { [index: number]: Nota };
}

export class Parte {

  id: number;
  fecha: Date;
  codigo: string;
  urgente: boolean;
  direccion: string;
  poblacion: string;
  cliente: string;
  oficial: string;
  completado: boolean;
  data: string[];
  extra: string[];
  observaciones: string;
  type: WorkType;
  files: string[];
  coordinates?: Coordinates;
  notas: Nota[];

  constructor(parte: IParte) {
    this.id = parte.id;
    this.fecha = new Date(parte.fecha);
    this.codigo = parte.codigo;
    this.urgente = parte.urgente ?? false;
    this.direccion = parte.direccion;
    this.poblacion = parte.poblacion;
    this.cliente = parte.cliente;
    this.oficial = parte.oficial;
    this.completado = parte.completado;
    this.data = parte.data;
    this.extra = parte.extra;
    this.observaciones = parte.observaciones;
    this.type = parte.type;
    this.files = parte.files;
    this.coordinates = parte.coordinates;
    this.notas = Object.values(parte.notas);
  }

  get images(): string[] {
    const api = environment.api.replace('index.php', '');
    return this.files.map(file => `${api}/${file}`);
  }

  toString() {
    return `(${this.codigo}) ${this.type} - ${this.cliente}`;
  }
}
